<template>
  <v-dialog
    :value="show"
    max-width="550"
    @input="e => $emit('update:show', e)"
  >
    <v-card>
      <v-card-title class="headline py-5">
        {{ title }}
      </v-card-title>
      <v-card-text class="pb-0">
        <!-- <p class="subtitle-1">
          We've calculated your site's area from its boundary:
        </p> -->
        <p
          v-if="displayedArea"
          class="d-flex align-end justify-space-between"
        >
          <span>
            <span class="display-2 font-weight-medium success--text">{{ displayedArea.split(' ')[0] }}&nbsp;</span>
            <span class="display-1 success--text">{{ displayedArea.split(' ').slice(1).join(' ') }}</span>
          </span>
          <v-btn
            v-if="prominentRedraw"
            text
            color="shamrock"
            @click="$emit('redraw')"
          >
            <v-icon left>
              <!-- mdi-brush -->
              mdi-brush
            </v-icon>
            Redraw on map
            <!-- <v-icon
              right
              small
            >
              mdi-open-in-new
            </v-icon> -->
          </v-btn>
        </p>
        <p class="subtitle-1">
          Confirm Project Area below. Adjust area as needed for accuracy and provide footprint of any buildings within Project Area.
        </p>
        <v-form>
          <v-row dense>
            <v-col class="d-flex">
              <v-text-field
                v-model.number="manualArea"
                label="Project area"
                class="mr-2"
                style="max-width: 150px;"
                type="number"
                :min="0"
                :rules="[v => v > 0 || 'Area must be more than zero']"
              />
              <v-select
                v-model="manualAreaUnits"
                label="Units"
                :items="manualAreaUnitOptions"
                item-value="text"
                item-text="text"
                style="max-width: 110px;"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <ValueWithDataBook>
                <template v-slot:variable-name>
                  Building Footprint
                </template>
                <template v-slot:line2>
                  Area<br>
                  <TextFieldAndSlider
                    :initial-value="manualBuildingFootprint"
                    :min="0"
                    :max="manualArea"
                    :show-slider="true"
                    :icon="iconFromType['area']"
                    @change="(val) => manualBuildingFootprint = val"
                  />
                </template>
                <template v-slot:tooltip>
                  Sum of outer square footage of all buildings and
                  enclosed structures anticipated in final design.
                </template>
              </ValueWithDataBook>
              <ValueWithDataBook>
                <template v-slot:variable-name>
                  Site Area
                </template>
                <template v-slot:variable-value>
                  <SmartValue
                    :value="totalSiteArea"
                    :from-unit="'m2'"
                    :to-imperial="'ft2'"
                    :to-metric="'m2'"
                  />
                </template>
                <template v-slot:tooltip>
                  This represents your final landscape area
                  outside of buildings, including all hardscape
                  and softscape.
                </template>
              </ValueWithDataBook>
            </v-col>
          </v-row>
          <v-fade-transition>
            <v-btn
              v-if="areaInM2 !== calculatedArea"
              elevation="0"
              color="metal darken-1"
              class="mt-3"
              text
              @click="resetAreaToCalculated"
            >
              <v-icon left>
                mdi-refresh
              </v-icon>
              Reset
            </v-btn>
          </v-fade-transition>
        </v-form>
      </v-card-text>
      <v-card-actions class="px-5 py-3">
        <v-btn
          text
          color="metal"
          @click="$emit('back')"
        >
          <v-icon
            left
          >
            mdi-arrow-left
          </v-icon>
          {{ prominentRedraw ? 'Discard changes' : 'Redraw boundary' }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="shamrock"
          text
          class="px-3"
          :loading="manualAreaInputSaving"
          :disabled="nextDisabled"
          @click="next"
        >
          <span v-if="prominentRedraw">Save changes</span>
          <span v-else>Continue with {{ areaInM2 === calculatedArea ? 'existing' : 'new' }} value</span>
          <v-icon right>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import { convertArea } from '@turf/helpers'
import getReasonableAreaUnits from '@/helpers/getReasonableAreaUnits'
import SmartValue from '@/components/atoms/SmartValue.vue'
import ValueWithDataBook from '@/components/molecules/ValueWithDataBook.vue'
import TextFieldAndSlider from '@/components/atoms/TextFieldAndSlider.vue'
import { createNamespacedHelpers } from 'vuex'
import { iconFromType } from '@/schema/units'

const { mapGetters: mapProjectGetters } = createNamespacedHelpers('projects')

export default {
  name: 'ManualAreaInputDialog',
  components: {
    TextFieldAndSlider,
    ValueWithDataBook,
    SmartValue
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    calculatedArea: {
      type: Number,
      required: true
    },
    calculatedBuildingFootprint: {
      type: Number,
      required: true
    },
    areaInM2: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      default: 'Area calculated from boundary'
    },
    systemOfMeasurement: {
      type: String,
      default: 'imperial'
    },
    manualAreaInputSaving: {
      type: Boolean,
      default: false
    },
    prominentRedraw: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      iconFromType,
      buildingFootprintInM2: this.calculatedBuildingFootprint,
      manualAreaUnits: null,
      allManualAreaUnitOptions: [
        { text: 'sq feet', multiplier: 10.764, systemOfMeasurement: 'imperial' },
        { text: 'm2', multiplier: 1, systemOfMeasurement: 'metric' },
        { text: 'acres', multiplier: 0.000247105, systemOfMeasurement: 'imperial' },
        { text: 'hectares', multiplier: 0.0001, systemOfMeasurement: 'metric' }
      ]
    }
  },
  computed: {
    ...mapProjectGetters(['project']),
    manualAreaUnitOptions () {
      let ret = this.allManualAreaUnitOptions
      if (this.systemOfMeasurement) {
        ret = ret.filter((x) => x.systemOfMeasurement === this.systemOfMeasurement)
      }
      return ret
    },
    manualArea: {
      get () {
        const { multiplier } = this.manualAreaUnitOptions.find(({ text }) => text === this.manualAreaUnits)
        return Math.round(this.areaInM2 * multiplier * 1000) / 1000
      },
      set (val) {
        const { multiplier } = this.manualAreaUnitOptions.find(({ text }) => text === this.manualAreaUnits)
        this.$emit('update:areaInM2', val / multiplier)
      }
    },
    displayedArea () {
      if (!this.calculatedArea) {
        return ''
      }
      let system = this.systemOfMeasurement || 'imperial'
      const { value, units } = getReasonableAreaUnits(this.calculatedArea, system)
      return `${value} ${units}`
    },
    manualBuildingFootprint: {
      get () {
        const { multiplier } = this.manualAreaUnitOptions.find(({ text }) => text === this.manualAreaUnits)
        return Math.round(this.buildingFootprintInM2 * multiplier * 1000) / 1000
      },
      set (val) {
        const { multiplier } = this.manualAreaUnitOptions.find(({ text }) => text === this.manualAreaUnits)
        this.buildingFootprintInM2 = val / multiplier
      }
    },
    totalSiteArea () {
      return this.areaInM2 - this.buildingFootprintInM2
    },
    nextDisabled () {
      return !this.areaInM2 || (this.prominentRedraw && (
        this.areaInM2 === this.calculatedArea &&
        this.buildingFootprintInM2 === this.calculatedBuildingFootprint
      ))
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        this.visible = val
      }
    },
    manualAreaUnitOptions: {
      immediate: true,
      handler (options) {
        this.manualAreaUnits = options[0].text
      }
    }
  },
  methods: {
    resetAreaToCalculated () {
      this.manualArea = this.calculatedArea
      this.$emit('update:areaInM2', this.calculatedArea)
      this.$store.dispatch('showSnackbar', 'Area was reset to existing value')
    },
    next () {
      this.$emit('next', { buildingFootprintInM2: this.buildingFootprintInM2 })
    }
  }
}
</script>

<style>

</style>
